<template>
  <div class="stripe-content">
    <div class="content-header">
      <div class="header-logo">
        <img src="../../../../static/images/payment/marketplace.png" alt="logo">
      </div>
      <div class="credit-division"></div>
      <button class="btn close">
        <i class="ion-close-round icon" data-icon="ion" @click="$emit('close')"></i>
      </button>
    </div>
    <div class="content-body">
      <form name="stripe" id="stripe-form" @submit.prevent>
        <div class="form-item">
          <i class="icon ion-ios-email" data-icon="ion"></i>
          <input type="email" required data-stripe="email" :placeholder="$t('checkout.labelStripeEmail')"
                 :class="{'error': errorParam === 'email'}">
        </div>
        <div class="form-item">
          <i class="icon ion-card" data-icon="ion"></i>
          <input type="text" required data-stripe="number" :placeholder="$t('checkout.labelStripeCard')"
                 :class="{'error': errorParam === 'number'}">
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="form-item col-xs-6">
              <i class="icon ion-ios-calendar-outline" data-icon="ion"></i>
              <input type="text" required data-stripe="exp_month" :placeholder="$t('checkout.labelStripeMonth')"
                     :class="{'error': errorParam === 'exp_month'}">
            </div>
            <div class="form-item col-xs-6">
              <i class="icon ion-ios-calendar-outline" data-icon="ion"></i>
              <input type="text" required data-stripe="exp_year" :placeholder="$t('checkout.labelStripeYear')"
                     :class="{'error': errorParam === 'exp_year'}">
            </div>
          </div>
        </div>
        <div class="form-item">
          <i class="icon ion-ios-locked-outline" data-icon="ion"></i>
          <input type="text" required data-stripe="cvc" :placeholder="$t('checkout.labelStripeCvc')"
                 :class="{'error': errorParam === 'cvc'}">
        </div>
        <div class="form-item">
          <i class="icon ion-ios-location" data-icon="ion"></i>
          <input type="text" required data-stripe="address_zip" :placeholder="$t('checkout.labelStripePostal')"
                 :class="{'error': errorParam === 'address_zip'}">
        </div>
        <div class="text-center">
          <button type="button" class="btn primary" v-if="!price">计算中...</button>
          <template v-else>
            <button type="submit" class="btn primary" id="stripe-pay-btn" @click="startPay" v-if="!loading">
              {{$t('checkout.labelStripeButton')}} {{price}}
            </button>
            <button type="button" class="btn primary loading-btn" v-else>
              支付中
              <span class="loading-icon"></span>
            </button>
          </template>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stripe-modal',
  props: {
    price: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      errorParam: ''
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    startPay () {
      this.errorParam = ''
      if (!window.Stripe) {
        this.$emit('lack')
        this.$toast({
          message: '支付加载失败<br/>请检查网络连接',
          position: 'bottom'
        })
        return
      }
      this.loading = true
      window.Stripe.card.createToken(document.querySelector('#stripe-form'), (status, data) => {
        this.loading = false
        switch (status) {
          case 200:
            return this.$emit('paid', data.id)
          default:
            this.errorParam = data.error.param
        }
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss" scoped>
  @import '../../../styles/variables';
  .stripe-content {
    .content-header {
      position: relative;
      min-height: 5rem;
      padding: 1rem;
      background-color: #e8e9eb;
      border-bottom: 1px solid #e8e9eb;
      border-radius: 6px 6px 0 0;
      .header-logo {
        position: absolute;
        top: -35px;
        left: 50%;
        margin-left: -35px;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid #fff;
        box-shadow: 0 0 0 1px rgba(0,0,0,.18), 0 2px 2px 0 rgba(0,0,0,.08);
      }
      .close {
        position: absolute;
        right: 1rem;
        background: darken(#bbb, 20%);
        height: 28px;
        width: 28px;
        border-radius: 50%;
      }
      .credit-division {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        background-color: #e8e9eb;
        &:before {
          content: '';
          display: block;
          border-top: 1px solid #fff;
          box-shadow: 0 -1px 0 0 #d2d2d3;
          border-radius: 5px 5px 0 0;
          width: 100%;
          height: 4px;
          background-color: #f5f5f7;
          margin-bottom: -3px;
        }
      }
    }
    .content-body {
      form {
        padding: 60px 30px 30px;
        @media (max-width: 768px) {
          padding: 20px 30px;
        }
        #stripe-pay-btn {
          height: 36px;
          font-weight: 500;
          box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
        }
      }
      .form-item {
        position: relative;
        background-color: #fff;
        border-radius: 6px;
        margin-bottom: 1rem;
        box-shadow: inset 0 1px 1px 0 hsla(240,1%,49%,.3), 0 1px 0 0 hsla(0,0%,100%,.7);
        padding: 0;
        .icon {
          position: absolute;
          font-size: 20px;
          line-height: 36px;
          padding: 0 4px 0 8px;
          color: #3bae9c;
        }
        input {
          appearance: none;
          padding: 3px 20px 3px 32px;
          display: inline-block;
          font-size: 14px;
          width: 100%;
          background-color: transparent;
          border: none;
          transition: all .3s;
          font-weight: 500;
          color: #555;
          &.error{
            box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
            border: 1px solid  #ff7076;
          }
          &:hover, &:focus {
            border-color: #7caadd;
            border-radius: 4px;
            box-shadow: inset 0 1px 1px rgba(76,107,153,.15), 0 1px 0 transparent, 0 0 6px #326cc0;
            z-index: 100;
            background-color: #fff;
          }
        }
      }
    }
    .loading-icon {
      height: 14px;
      width: 14px;
      border-width: 2px;
      border-color: #fff #fff #fff transparent;
      vertical-align: middle;
    }
    .loading-btn {
    }
  }
</style>
